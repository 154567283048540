.image-side-by-side-item {
  height: 439px;
  width: 50%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  //Responsivo
  @media only screen and (max-width: 1023px) {
    width: 100%;
    height: 350px;
  }

  @media only screen and (max-width: 767px) {
    height: 250px;
  }
}
