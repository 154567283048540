@import "../../styles/settings/colors";
@import "../../styles/settings/fonts";

@import "../../styles/tools/mixins";

.news-slider {
  margin: 0 auto 26px;
  width: 100%;

  &--title {
    width: 100%;

    .text {
      font-size: 23px;
      line-height: 30px;
      color: $darkest_blue;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      margin: 0;
      padding-bottom: 26px;
    }
  }

  .slider {
    padding-bottom: 37px;
    width: 100%;

    .slick {
      &-dots {
        bottom: 0;
        margin: 0;
        left: 50%;
        padding: 0;
        position: absolute;
        transform: translate(-50%, 0);

        > li {
          background: transparent;
          margin: 0 5px;
          float: left;

          button {
            width: 11px;
            height: 11px;
            @include rem("font-size", 0px);
            border: 1px solid $blue;
            border-radius: 100%;
            background-color: $white;
            cursor: pointer;
            float: left;
            padding: 0;
            outline: none;
          }

          &.slick-active {
            button {
              background-color: $blue;
            }
          }
        }
      }

      &-list,
      &-track {
        touch-action: pan-y;
      }

      &-list {
        padding: 0;
        overflow: hidden;
        width: 100%;
      }

      &-slide {
        align-items: flex-start;
        display: inline-flex;
        justify-content: center;

        & > div {
          flex: 1;
        }
      }

      &-slider {
        width: 100%;
      }

      &-track {
        width: 100%;
        vertical-align: top;
        display: inline-flex;
        gap: 20px;
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width: 768px) {
    .slider {
      padding-bottom: 26px;

      .slick {
        &-dots {
          bottom: 0;
          text-align: center;
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .slider {
      .slick {
        &-track {
          gap: 3px;
        }
      }
    }
  }
}
