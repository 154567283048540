@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.pagination-button {
  padding: 0;
  scale: 0.5;

  svg {
    path {
      fill: $yellow;
    }
  }

  &.prev {
    svg {
      transform: rotate(180deg);
    }
  }

  &.disabled {
    pointer-events: none;

    svg {
      filter: grayscale(0.8);
      opacity: 40%;
    }
  }
}