@import "../../../styles/settings/colors";
@import "../../../styles/settings/fonts";

@import "../../../styles/tools/mixins";

.list-carrousel-business-photo-album {
  max-width: 1326px;
  width: 100%;
  height: auto;
  margin: 35px auto 47px;

  .container-element {
    padding: 0;
  }

  .slider {
    width: 100%;

    .slick {
      &-dots {
        width: auto;
        height: 11px;
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translate(-50%, 0);

        > li {
          background: transparent;
          margin: 0 6px;
          height: 11px;
          width: 11px;

          button {
            @include rem("font-size", 0px);
            border: 2px solid #459ad9;
            border-radius: 100%;
            background-color: $white;
            cursor: pointer;
            height: 100%;
            width: 100%;
            padding: 0;
            margin: 0;
          }

          &.slick-active {
            button {
              background-color: #459ad9;
            }
          }
        }
      }

      &-list,
      &-track {
        touch-action: pan-y;
        border-radius: 5px;
      }

      &-list {
        padding: 0;
        overflow: hidden;
        width: 100%;
      }

      &-slide {
        align-items: flex-start;
        display: inline-flex;
        justify-content: center;

        > div {
          width: 100%;

          .card {
            display: flex;
            flex-direction: column;
            position: relative;

            .link {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 78px;
              padding: 14px 24px;
              background-color: rgba(0, 13, 69, 0.5);
              overflow: hidden;

              .icon {
                display: none;
              }

              .text {
                width: 100%;
                float: unset;
                padding: 0;
                display: flex;

                p {
                  @include rem("font-size", 16px);
                  line-height: 20px;
                  font-family: $font-default;
                  font-weight: 700;
                  color: #ffffff;
                  text-transform: unset;
                }
              }
            }
          }
        }
      }
    }
  }

  //Responsivo
  @media only screen and (max-width: 1365px) {
    max-width: 1000px;
  }

  @media only screen and (max-width: 1023px) {
    max-width: 700px;
  }

  @media only screen and (max-width: 767px) {
    max-width: 336px;
  }
}
