@import '../../../styles/settings/colors';

.header-top {
  align-items: center;
  display: inline-flex;
  flex-flow: row wrap;
  min-height: 130px;
  padding: 0;
  position: relative;
  transition: all 400ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  width: 100%;

  .container-element {
    align-items: center;
    display: inline-flex;
    justify-content: space-between;
  }

  &[data-min="true"] {
    height: 70px;
    padding: 0;
  }

  .header-navigation {
    .container-element {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    min-height: 88px;
    align-items: center;
    display: flex;
    padding: 20px 0 0;

    &[data-min="true"] {
      height: 55px;
    }
  }
}