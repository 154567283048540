@import '../../../../../styles/settings/colors';
@import '../../../../../styles/settings/fonts';

@import '../../../../../styles/tools/mixins';


.header-menu-submenu-item {
  float: left;
  max-width: 233px;
  width: 100%;

  &--link {
    margin: 0;
    width: auto;

    .text {
      font-family: "Raleway";
      font-size: 18px;
      line-height: 18px;
      color: $blue;
      font-weight: 600;
      margin: 0;
      text-transform: inherit;
      transition: all ease-in-out 0.4s;

      &:hover {
        color: $dark_blue;
      }
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    border: none;
    max-width: none;

    .header-menu-submenu-item {      
      &--link {
        margin: 0;
        padding: 25px 40px 25px 50px;
        text-align: left;
        width: 100%;
        background-color: #F7F7F7;

        .icon {
          @include rem('font-size', 18px);
          color: $white;
          right: 25px;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
        }

        .text {
          @include rem('font-size', 16px);
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          text-transform: uppercase;
          white-space: nowrap;
        }

        &:before,
        &:after {
          background-color: rgba(0,58,105,.102);
          content: '';
          height: 1px;
          left: 0;
          position: absolute;
          top: 100%;
          width: 100%;
        }

        &:before {
          top: 0;
        }
      }
    }
  }
}