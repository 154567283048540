@import '../../../styles/settings/colors';
@import '../../../styles/tools/mixins';

.time-line-controls {
  left: 0;
  position: fixed;
  pointer-events: none;
  top: 150px;
  width: 100%;
  z-index: 10;

  &--container {
    height: calc(100vh - 165px);
    pointer-events: none;
    float: right;
    max-width: 180px;
    overflow: hidden;
    overflow-y: auto;
    padding-left: 20px;
    text-align: right;
    @include scrollV(4px, 'transparent', $white);

    > button {
      border-radius: 0;
      background-color: $light_blue;

      &[data-active='true'] {
        background-color: $orange;
      }
    }

    /* Small Devices, Tablets */
    @media only screen and (max-width : 768px) {
      max-width: 140px;
      height: calc(100vh - 55px);

      > button {
        height: 70px !important;
      }
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 480px) {
      align-items: center;
      display: inline-flex;
      flex-flow: row nowrap;
      float: none;
      height: 70px;
      overflow: visible;
      padding: 0;
      justify-content: center;
      max-width: none;

      > button {
        overflow: visible;
        width: 75px !important;

        &:before {
          bottom: -4px;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &[data-active="true"] {
    animation: slide-in-right 0.4s cubic-bezier(0.38, 0.4, 0.58, 1) both;

    .time-line-controls {
      &--container {
        pointer-events: auto;
      }
    }
  }

  &[data-active="false"] {
    animation: slide-out-right 0.4s cubic-bezier(0.38, 0.4, 0.58, 1) both;

    .time-line-controls {
      &--container {
        pointer-events: none;
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    top: 88px;
    
    .container-element {
      overflow: auto;
      padding: 0 20px 10px;
      pointer-events: auto;
      text-align: center;
    }
  }
}