
.parallax-fix {
  display: inline-block;
  float: left;
  position: relative;
  width: 100%;

  .parallax-outer,
  .parallax-inner {
    display: inline-block;
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
  }
}