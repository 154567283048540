.accordion-list {
  max-width: 876px;
  width: 100%;
  height: auto;
  margin: 0 auto 35px;
  display: flex;

  .container-element {
    max-width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 35px;
  }

  //Responsivo
  @media only screen and (max-width: 1023px) {
    max-width: 700px;
  }

  @media only screen and (max-width: 767px) {
    max-width: 336px;
  }
}
