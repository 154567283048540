.container {
  float: left;
  position: relative;
  text-align: center;
  width: 100%;

  .container-element {
    float: none;
    max-width: 1360px;
    padding: 0 20px;
    text-align: left;
    width: 100%;
  }

  &[data-type="2"] {
    .container-element {
      max-width: 900px;
      text-align: center;
    }
  }
}