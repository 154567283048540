@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.webdoor-info {
  display: none;
  float: left;
  pointer-events: auto;
  width: 50%;

  &--btn-link {
    width: fit-content;
    background-color: $light-green;
    border: none;
    border-radius: 20px;
    color: $dark-blue;
    float: left;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02em;
    margin: 0;
    padding: 11px 32px;
    text-decoration: none;
    text-transform: capitalize;
    transition: all ease-in 0.3s;

    &:hover {
      background-color: $green;
    }
  }

  .h2 {
    font-size: 60px;
    font-weight: 300;
    color: $white;
    float: left;
    line-height: 60px;
    width: fit-content;
    text-align: center;
    margin: 0;

    p {
      color: $white;
    }
  }

  &[data-active="true"] {
    width: 100%;
    animation: swing-in-top-fwd-t 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 1024px) {
    width: 100%;

    .h2 {
      @include rem('font-size', 35px);
      line-height: 31.5px;
    }

    &[data-active="true"] {
      animation: none;
    }
  }
}