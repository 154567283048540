@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.multimedia-gallery-slider {
  float: left;
  margin: 0 auto 40px;
  width: 100%;

  .container-element {
    text-align: center;
  }

  .slider {
    float: none;
    height: 480px;
    max-width: 854px;
    width: 100%;

    .slick {
      &-dots {
        bottom: -35px;
        margin: 0;
        left: 50%;
        padding: 0;
        position: absolute;
        transform: translate(-50%, 0);

        > li {
          background: transparent;
          margin: 0 5px;
          float: left;

          button {
            @include rem('font-size', 0px);
            border: 1px solid $blue;
            background-color: $white;
            cursor: pointer;
            height: 13px;
            float: left;
            padding: 0;
            width: 13px;
          }

          &.slick-active {
            button {
              background-color: $blue;
            }
          }
        }
      }

      &-list,
      &-track { 
        touch-action: pan-y;
      }
  
      &-list {
        float: left;
        padding: 0;
        overflow: hidden;
        width: 100%;
      }
  
      &-slide {
        align-items: flex-start;
        display: inline-flex;
        justify-content: center;
        
        > div {
          width: 100%;
        }
      }
  
      &-slider {
        float: left;
        width: 100%;
      }
  
      &-track {
        float: left;
        width: 100%;
        vertical-align: top;
      }
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    padding: 0 70px;

    .slider {
      height: auto;
      max-width: none;
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    margin: 20px 0 60px;
    padding: 0;

    .container-element {
      padding: 0;
    }
  }
}