@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';
@import '../../../styles/components/animations';

.image-text-slider--info {
  background-color: $blue;
  float: left;
  padding: 80px 85px 120px 60px;
  max-width: 530px;
  width: 100%;
  z-index: 11;

  &--item {
    left: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    gap: 5px;

    .title,
    .subtitle,
    .description {
      margin: 0;
      color: $white;
    }

    .subtitle {
      margin-bottom: 12px;
      font-weight: 300;
      line-height: 65px;
    }

    .description {
      margin-bottom: 17px;
      @include rem('font-size', 18px);
    }

    .link-button {
      background-color: transparent;
      border: 1px solid $light_blue;
      color: $light_blue;

      &:hover {
        background-color: $light_blue;
        color: $white;
      }
    }

    &[data-active="true"] {
      display: inline-block;
      opacity: 1;
      left: initial;
      position: relative;

      .description,
      .subtitle,
      .title {
        animation: swing-in-top-fwd-t 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    max-width: none;
    padding: 25px 30px 95px;

    .image-text-slider--info {
      &--item {
        .description {
          margin-bottom: 0;
        }
      }
    }
  }
}