@import "../../../../styles/settings/colors";
@import "../../../../styles/settings/fonts";

@import "../../../../styles/tools/mixins";

.list-carrousel-business-photo-album-button {
  background-color: transparent;
  bottom: -47px;
  height: 48px;
  position: absolute;
  width: 60px;
  padding: 0;
  margin: 0;
  z-index: 2;

  &:before {
    background-color: transparent;
    content: url("../../../../assets/icons/ListCarouselArrow.svg");
    left: 0;
    position: absolute;
    top: 0;
    width: 27px;
    height: 23px;
    transform: translate(50%, 50%);
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;

    &::before {
      rotate: 180deg;
      transform: translate(-50%, -50%);
    }
  }

  &[data-disabled="true"] {
    &::before {
      content: url("../../../../assets/icons/ListCarouselDisabledArrow.svg");
    }
  }

  /* Extra Small Devices, Phones */
  @media only screen and (max-width: 767px) {
    display: none;
  }
}
