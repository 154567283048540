@import '../../../../../styles/settings/colors';
@import '../../../../../styles/settings/fonts';

@import '../../../../../styles/tools/mixins';

.time-line-item-slider-button {
  background-color: rgba(0, 0, 0, 0.45);
  height: 54px;
  cursor: pointer;
  right: 0;
  pointer-events: auto;
  position: absolute;
  width: 54px;

  svg {
    width: 30px;

    path {
      fill: $light_blue;
    }
  }

  &[data-disabled="true"] {
    pointer-events: none;
    
    svg {
      filter: grayscale(0.8);
      opacity: 40%;
    }
  }

  &.prev {
    top: 134px;

    svg {
      rotate: 180deg;
    }
  }

  &.next {
    top: 80px;
  }

  &:before {
    background-color: #000;
    content: '';
    left: 0;
    height: 100%;
    opacity: 0.45;
    position: absolute;
    top: 0;
    width: 100%;
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    padding: 24px 0 26px;

    &.next {
      top: 110px;

      svg {
        transform: translateY(-45%);
      }
    }
    
    &.prev {
      top: 164px;

      svg {
        transform: translateY(45%);
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    height: 38px;
    padding: 10px 0;
    width: 35px;

    .icon {
      @include rem('font-size', 20px);
    }

    &.next {
      left: initial;
      right: 0;
      top: 0;
    }

    &.prev {
      left: 0;
      right: initial;
      top: 0;
    }
  }
}