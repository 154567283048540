.related-content-list {
  display: flex;
  flex-direction: column;

  .container {
    &-element {
      margin: 0 auto;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 768px) {
    .container {
      &-element {
        flex-flow: column wrap;
      }
    }
  }
}
