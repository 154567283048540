/* --- COLORS --- */

$light_blue: #34B6E4;
$blue: #0063BE;
$dark_blue: #003A69;
$darkest_blue: #081B6A;

$yellow: #FFC82E;

$lime_green: #BED600;
$light_green: #8FD400;
$green: #5CC151;
$dark_green: #00693E;

$orange: #F4AA00;
$dark_orange: #FF7900;

$light_gray: #F7F7F7;
$gray: #575757;

$white: #FFFFFF;

$black: #000000;
