@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.single-video {
  float: left;
  margin: 0 auto 40px;
  width: 100%;

  &--container {
    float: none;
    max-width: none;
    width: auto;
    border-radius: 15px;
    overflow: hidden;
  }

  .container-element {
    text-align: center;
  }

  &--image {
    display: inline-block;
    height: 100%;
    left: 0;
    opacity: 1;
    padding: 0;
    position: absolute;
    transition: all 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    top: 0;
    width: 100%;
    pointer-events: none;
    z-index: 2;

    .video-button {
      width: 70px;
      height: 70px;
      background-color: $yellow;
      padding: 0;
      margin: 0;
      border: none;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: all;
      z-index: 1;
      cursor: pointer;
      transition: all ease-in 0.3s;

      svg {
        margin-left: 3px;
        scale: 1.3;
        transition: all ease-in 0.3s;
      }
    }

    &--img {
      float: left;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &:after {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &:hover {
      .video-button {
        width: 80px;
        height: 80px;

        svg {
          scale: 1.5;
        }
      }
    }
  }

  &--video {
    max-width: 980px;
    max-height: 552px;

    video {
      object-fit: cover;
    }
  }

  &[data-active="true"] {
    .single-video {
      &--image {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  &:has(video:paused) {
    .single-video {
      &--image {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    margin: 0 0 20px;

    .single-video {
      &--container {
        height: 0;
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        width: 100%;
      }

      &--image {
        .video-button {
          width: 60px;
          height: 60px;
    
          svg {
            scale: 1;
          }
        }
      }

      &--video {
        height: 100% !important;
        left: 0;
        position: absolute;
        top: 0;
        width: 100% !important;
      }
    }
  }
}