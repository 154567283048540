@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.form-success {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  width: 100%;

  .icon {
    font-size: 50px;
    font-weight: 500;
    color: $green;
  }

  .title {
    font-family: 'Raleway';
    font-size: 22px;
    line-height: 24px;
    font-weight: 600;
    color: $blue;
    margin: 0;
  }

  .description {
    font-family: 'Raleway';
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    color: $black;
    margin: 0;
  }
  
  &[data-type="error"] {
    .icon,
    .title {
      color: $orange;
    }
  }
}