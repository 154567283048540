@import '../../../../../styles/settings/colors';
@import '../../../../../styles/settings/fonts';

@import '../../../../../styles/tools/mixins';

.time-line-item-slider-item {
  display: none;
  height: 50vh;
  overflow: hidden;
  position: absolute;
  pointer-events: auto;
  width: 100%;
  will-change: transform;

  div {
    &:has(img) {
      width: 0;
      height: 0;
      display: none;
      visibility: hidden;
      opacity: 0;
      
      img {
        width: 0;
        height: 0;
        display: none;
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  .text,
  .title {
    color: $white;
    margin: 0;
    float: left;
    width: 100%;
  }

  .text {
    @include rem('font-size', 18px);
    line-height: 1.4em;
    padding: 0 40px 0 0;

    b {
      color: $orange;
    }
  }

  .title {
    border: none;
    border-bottom: 1px solid $white;
    padding: 15px 0;
    display: flex;
    gap: 12px;

    &--container {
      float: left;
    }

    .text,
    .year {
      @include rem('font-size', 30px);
      color: $white;
      font-weight: 400;
      float: left;
      line-height: 1em;
      width: auto;
    }

    .year {
      font-weight: 600;
      color: $light_blue;
    }
  }

  .paragraph.text {
    padding-top: 15px;
  }

  .ScrollbarsCustom {
    &-Track {
      background-color: transparent !important;
      border-radius: 0px !important;
      width: 6px !important;
    }

    &-Thumb {
      background-color: $white !important;
      border-radius: 0px !important;
    }
  }

  &[data-active="true"] {
    display: inline-block;

    &[data-dir="next"] {
      animation: slide-in-right 0.4s cubic-bezier(0.38, 0.4, 0.58, 1) both;
    }

    &[data-dir="prev"] {
      animation: slide-in-left 0.4s cubic-bezier(0.38, 0.4, 0.58, 1) both;
    }
  }

  &[data-last="true"] {
    display: inline-block;

    &[data-dir="next"] {
      animation: slide-out-left 0.4s cubic-bezier(0.38, 0.4, 0.58, 1) both;
    }

    &[data-dir="prev"] {
      animation: slide-out-right 0.4s cubic-bezier(0.38, 0.4, 0.58, 1) both;
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    height: calc(100vh - 140px);

    &--container {
      align-items: center;
      display: inline-flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
    }

    .title {
      align-items: center;
      display: inline-flex;
      justify-content: flex-start;
      flex-flow: row wrap;
      height: 90px;

      .text {
        padding: 0;
        text-align: left;
        width: calc(100% - 100px);
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .text {
      padding: 0;
    }

    .title {
      height: 80px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    height: calc(100vh - 210px);

    &:has(.time-line-item--image) {
      &:before {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5625) 40.63%, rgba(0, 0, 0, 0.9) 100%);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 6;
      }
    }

    .ScrollbarsCustom {
      margin-top: 50px;
    }

    .text {
      @include rem('font-size', 16px);
      padding: 0 10px 70px 0;
    }

    .title {
      .icon {
        @include rem('font-size', 14px);
        top: 2px;
      }

      .text,
      .year {
        @include rem('font-size', 18px);
      }
    }
  }
}