@import "../../styles/settings/colors";
@import "../../styles/settings/fonts";

@import "../../styles/tools/mixins";

.image-side-by-side {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 35px auto;
  max-width: 1324px;
  width: 100%;
  height: auto;
  position: relative;

  //Responsivo
  @media only screen and (max-width: 1365px) {
    max-width: 950px;
  }

  @media only screen and (max-width: 1023px) {
    max-width: 700px;
    flex-direction: column;
  }

  @media only screen and (max-width: 767px) {
    max-width: 336px;
  }
}
