.time-line-item-slider-controls {
  height: 100%;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    height: 40px;
    top: 85px;
  }
}
