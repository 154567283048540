@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.hero-image {
  background-color: $gray;
  float: left;
  height: 160px;
  margin: 0 0 50px;
  width: 100vw;

  .image,
  .image img {
    float: left;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .h2 {
    max-width: 980px;
    width: 100%;
    margin: 0;
    font-weight: 300;
    font-size: 52px;
    line-height: 52px;
    text-align: center;
    color: $white;
    position: absolute;
    bottom: 27px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
  }

  &:before {
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  &:before {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
    height: 100%;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    height: 208px;

    .h2 {
      font-size: 35px;
      bottom: 18px;
    }
  }
}