@import "../../../styles/settings/colors";
@import "../../../styles/settings/fonts";

@import "../../../styles/tools/mixins";

.file {
  width: 100%;
  min-height: 120px;
  height: auto;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  padding: 35px 140px 35px 25px;
  position: relative;

  &::after {
    content: url("../../../assets/icons/file.svg");
    position: absolute;
    top: 34px;
    right: 78px;
  }

  .link {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8;
  }

  .title {
    font-size: 21px;
    line-height: 25px;
    color: #081b6a;
    font-family: $font-default;
    font-weight: 500;
    text-align: left;
    margin: 0;
    transition: all ease-in-out 0.4s;
  }

  &:hover {
    .title {
      color: $blue;
    }
  }

  //Responsivo
  @media only screen and (max-width: 767px) {
    padding: 35px 100px 35px 25px;

    &::after {
      right: 38px;
    }
  }
}
