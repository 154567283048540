@import '../../styles/settings/colors';


.header {
  float: left;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 999;

  &[data-type="2"] {
    position: relative;

    .header-top {
      background-color: $white;

      .header-top-submenu {
        .header-top-menu {
          &--item {
            &--link {
              .paragraph {
                color: $dark_blue;
              }
            }
          }
        }
      }

      .header-menu {
        &-item {
          &--link {
            .paragraph {
              color: $blue;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    position: fixed;

    &[data-type="2"] {
      position: fixed;
    }

    .header-top {
      background-color: $white;
    }
  }
}