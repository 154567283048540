.image-text-list {
  margin: 70px auto;
  width: fit-content;
  height: auto;

  .container {
    width: fit-content;
    height: auto;

    &-element {
      display: flex;
      flex-direction: column;
      gap: 40px;
      max-width: 1326px;
      padding: 0;
    }
  }

  //Responsivo
  @media only screen and (max-width: 1365px) {
    .container {
      &-element {
        max-width: 1000px;
      }
    }
  }

  @media only screen and (max-width: 1023px) {
    margin: 35px auto;

    .container {
      &-element {
        max-width: 100%;
        padding: 0 20px;
      }
    }
  }
}
