.board-management {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 30px;
  margin: 0 auto 35px;
  width: 100%;
  max-width: 1327px;

  &[data-center="true"] {
    display: flex;
    justify-content: center;
    
    > div {
      float: none;
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    grid-template-columns: 1fr 1fr;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    display: inline-block;

    > div {
      margin: 10px 0;
    }
  }
}