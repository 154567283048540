@import "../../../styles/settings/colors";
@import "../../../styles/settings/fonts";

@import "../../../styles/tools/mixins";

.related-content {
  min-height: 173px;
  background-color: $light_blue;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  transition: all ease-in 0.3s;

  &:first-child {
    border-radius: 5px 0 0 5px;
  }

  &:nth-child(2n) {
    background-color: $orange;
  }

  &:nth-child(3n) {
    background-color: $blue;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
  }

  &-link {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  &-description {
    @include rem('font-size', 28px);
    font-weight: 600;
    line-height: 28px;
    color: $white;
    pointer-events: none;
    transition: all ease-in 0.3s;
  }

  &:hover {
    filter: saturate(130%);

    .related-content-description {
      scale: 1.2;
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 768px) {
    &:first-child {
      border-radius: 5px 5px 0 0;
    }

    &:last-child {
      border-radius: 0 0 5px 5px;
    }
  }
}