@import "../../../styles/settings/colors";
@import "../../../styles/settings/fonts";

@import "../../../styles/tools/mixins";

.accordion {
  width: 100%;
  min-height: 67px;
  height: auto;
  border: 1px solid $light_blue;
  border-radius: 5px;
  overflow: hidden;

  &--title {
    width: 100%;
    position: relative;

    .btn {
      padding: 24px 22px;
      text-align: left;
      width: 100%;
    }

    .paragraph {
      @include rem("font-size", 20px);
      color: $dark_blue;
      font-family: $font-default;
      line-height: 20px;
      text-align: left;
      margin: 0;
      padding-right: 50px;
      
      &.title {
        font-weight: 400;
      }
    }

    &::before {
      content: url("../../../assets/icons/accordion-arrow-down.svg");
      position: absolute;
      top: 20px;
      right: 30px;
    }

    &[data-active="true"] {
      &::before {
        rotate: 180deg;
      }
    }
  }

  &--body {
    width: 100%;
    overflow: hidden;
    transition: height 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
      opacity 700ms ease, visibility 500ms;
    opacity: 1;
    visibility: visible;

    &[data-active="false"] {
      width: 0;
    }

    &--content {
      padding: 0 30px;
      width: 100%;

      p {
        margin: 0 0 20px;
        text-align: left;
        width: 100%;

        > p,
        > ul {
          text-align: left;
          margin: 0;
        }
      }
    }
  }
}
