@import "../../styles/settings/colors";
@import "../../styles/settings/fonts";

@import "../../styles/tools/mixins";

.files-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px auto;
  max-width: 652px;
  width: 100%;
  height: auto;

  &--title {
    @include rem("font-size", 40px);
    line-height: 40px;
    color: $dark_blue;
    font-weight: 300;
    font-family: $font-default;
    margin: 0;
  }

  .container-element {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: auto;
    padding: 0;
  }

  //Responsivo
  @media only screen and (max-width: 767px) {
    max-width: 100%;
    padding: 0 20px;
  }
}
