@import "../../../../styles/settings/colors";
@import "../../../../styles/settings/fonts";

@import "../../../../styles/tools/mixins";

.page-search-result-item {
  border-bottom: 1px solid #e3dbca;
  width: 100%;

  .link {
    padding: 20px 0;
    width: 100%;
    height: 100%;

    p {
      @include rem("font-size", 20px);
      line-height: 34px;
      font-family: $font-default;
      font-weight: 600;
      color: #da4252;
      width: 100%;
      margin: 0;
      transition: all ease-in-out 0.4s;
    }

    &:hover {
      p {
        color: #459ad9;
      }
    }
  }
}
