@import "../../styles/settings/colors";
@import "../../styles/settings/fonts";

@import "../../styles/tools/mixins";

.footer {
  border: none;
  border-top: 1px solid #e4e4e4;
  float: left;
  margin: 60px 0 0;
  width: 100vw;
  z-index: 10;

  .container-element {
    max-width: none;
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 0 auto;

    .footer-top {
      width: 100%;
      padding: 32px 0;

      &-wrapper {
        max-width: 1360px;
        width: 100%;
        margin: 0 auto;
        color: $dark_blue;
        display: flex;
        justify-content: space-between;
        gap: 30px;

        div {
          display: flex;
          flex-direction: column;
          gap: 7px;

          span {
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
          }

          &.info {
            max-width: 287px;

            ul {
              flex-direction: column;
              gap: 7px;

              li {
                gap: 8px;

                img {
                  max-width: 16px;
                  height: fit-content;
                }

                .description {
                  margin: 0;
                  p {
                    font-size: 14px;
                    line-height: 17px;
                    color: #003a69;
                    margin: 0;
                  }
                }
              }
            }
          }

          &.social {
            max-width: 260px;
            display: flex;
            flex-direction: column;
            gap: 30px;

            .social-media {
              ul {
                flex-wrap: wrap;
                gap: 15px;

                li {
                  img {
                    max-width: 20px;
                    height: fit-content;
                  }
                }
              }
            }

            .partners {
              ul {
                align-items: center;
                flex-wrap: wrap;
                gap: 20px;

                li {
                  img {
                    width: fit-content;
                    max-height: 52px;
                  }
                }
              }
            }
          }

          &.more {
            ul {
              flex-direction: column;
              gap: 7px;

              li {
                width: fit-content;
                position: relative;
                display: flex;
                align-items: center;

                a {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 16px;
                  color: $blue;
                  text-decoration: none;
                }

                &::after {
                  content: "";
                  width: 100%;
                  height: 1.5px;
                  background-color: $blue;
                  border-radius: 20px;
                  position: absolute;
                  top: calc(100% + 1px);
                  opacity: 0;
                  visibility: hidden;
                  transition: all ease-in 0.3s;
                }

                &:hover {
                  &::after {
                    opacity: 1;
                    visibility: visible;
                  }
                }
              }
            }
          }
        }
      }
    }

    .footer-bottom {
      width: 100%;
      background-color: $light_gray;
      padding: 17px 0;

      &-wrapper {
        max-width: 1360px;
        width: 100%;
        margin: 0 auto;
        color: $black;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;

        .copy {
          .paragraph {
            margin: 0;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            vertical-align: middle;
          }
        }

        .terms {
          ul {
            display: flex;
            align-items: center;
            gap: 20px;

            li {
              position: relative;
              display: flex;
              align-items: center;

              &::before {
                content: "";
                width: 1.5px;
                height: 100%;
                background-color: $gray;
                position: absolute;
                left: -10px;
                opacity: 0.5;
              }

              &:first-child {
                &::before {
                  content: none;
                }
              }

              a {
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                color: $blue;
                text-decoration: none;
              }

              &::after {
                content: "";
                width: 100%;
                height: 1.5px;
                background-color: $blue;
                border-radius: 20px;
                position: absolute;
                top: calc(100% + 1px);
                opacity: 0;
                visibility: hidden;
                transition: all ease-in 0.3s;
              }

              &:hover {
                &::after {
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
        }

        .signature {
          svg {
            filter: invert(1);
          }
        }
      }
    }

    ul {
      padding: 0;
      margin: 0;
      display: flex;

      li {
        display: flex;
        flex-flow: row nowrap;
      }
    }
  }

  @media only screen and (max-width: 1480px) {
    .container-element {
      .footer-top,
      .footer-bottom {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  @media only screen and (max-width: 980px) {
    .container-element {
      .footer-top,
      .footer-bottom {
        &-wrapper {
          flex-direction: column;
        }
      }

      .footer-top {
        &-wrapper {
          gap: px;

          div {
            &.logo {
              align-self: center;
            }

            &.social {
              gap: 20px;
            }

            &.more {
              display: none;
            }
          }
        }
      }

      .footer-bottom {
        &-wrapper {
          align-items: center;
          gap: 15px;

          .copy {
            order: 0;
          }

          .terms {
            order: 1;
          }

          .signature {
            order: 2;
          }
        }
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width: 768px) {
    margin: 75px 0 0;

    .container-element {
      .footer-bottom {
        &-wrapper {
          .copy {
            .paragraph {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
