@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.header-top-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 30px;

  &--item {
    float: left;

    &--link {
      float: left;
  
      .paragraph.light {
        font-weight: 500;
        font-size: 15px;
        line-height: 17.5px;
        color: $white;
        margin: 0;
      }

      svg {
        left: 20px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 20px;

        & + p {
          padding-left: 30px;
        }
      }
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    padding: 0;
    width: 100%;
    display: inline-block;
    
    .header-top-menu {
      &--item {
        border: none;
        border-bottom: 1px solid #E4E4E4;
        margin: 0;
        width: 100%;

        &--link {
          padding: 30px 20px;
          text-align: left;
          width: 100%;

          .icon {
            @include rem('font-size', 18px);
            right: 25px;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
          }

          .paragraph {
            @include rem('font-size', 16px);
            line-height: 16px;
            font-weight: 700;
            color: #0063BE;
            font-family: "Raleway";
          }

          &[data-icon="true"] {
            padding-left: 60px;
          }
        }
      }
      
      &:before {
        background-color: $light_blue;
        content: '';
        height: 100%;
        left: 0;
        opacity: 0.15;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
}