@import '../../styles/settings/colors';

.information-card {
  max-width: 548px;
  width: 100%;
  height: 100%;
  background-color: $yellow;
  padding: 60px 55px;
  margin: 0 auto 40px;
  color: $black;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 15px;
  float: left;

  .title {
    font-size: 22px;
    line-height: 24.2px;
  }

  .info-list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    li {
      font-size: 18px;
      line-height: 29px;
      letter-spacing: 0.02em;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      flex: 1;
      word-break: break-all;

      &::before {
        max-width: 19px;
        max-height: 19px;
        margin-bottom: 5px;
      }

      &.info-name {
        &::before {
          content: url('../../assets/icons/info-user-icon.svg');
        }
      }

      &.info-email {
        &::before {
          content: url('../../assets/icons/info-email-icon.svg');
        }
      }

      &.info-phone {
        &::before {
          content: url('../../assets/icons/info-phone-icon.svg');
        }
      }

      a {
        font-weight: 600;
        color: $blue;
        text-decoration: none;
        position: relative;
        transition: color ease-in 0.3s;

        &::after {
          content: "";
          width: 100%;
          height: 2px;
          background-color: $dark_blue;
          border-radius: 20px;
          position: absolute;
          top: 80%;
          left: 0;
          opacity: 0;
          visibility: hidden;
          transition: all ease-in 0.3s;
        }
      }

      &:hover {
        a {
          color: $dark_blue;

          &::after {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  @media only screen and (max-width : 767px) {
    width: calc(100% - 40px);
    padding: 40px 11px;

    .title {
      font-size: 18px;
      line-height: 20px;
    }

    .info-list {
      li {
        font-size: 16px;
        line-height: 22px;

        &::before {
          margin-bottom: 3px;
        }

        span {
          display: none;
        }
      }
    }
  }
}