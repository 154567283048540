@import "../../../../styles/settings/colors";
@import "../../../../styles/settings/fonts";

@import "../../../../styles/tools/mixins";

.header-top-submenu {
  align-items: center;
  display: flex;
  gap: 30px;
  float: right;
  text-align: right;

  &-contact {
    display: flex;
    align-items: center;
    width: auto;
    height: 42px;
    padding: 10px 10px 14px;
    margin-bottom: 14px;
    background-color: $blue;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    span {
      font-size: 14px;
      font-weight: 700;
      color: $white;
    }

    @media only screen and (max-width : 1024px) {
      width: 100%;
      padding: 14px 20px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .btn-email {
    svg {
      float: left;
      width: 20px;
    }
  }

  //Botão hamburguer
  .btn-open {
    width: 23px;
    height: 18px;
    padding: 3px;
    position: absolute;
    bottom: -17px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 23px;
      height: 18px;
      background-size: cover;
      background-repeat: no-repeat;
      transition: opacity 0.1s;
    }

    &::before {
      background-image: url("../../../../assets/icons/burger-menu.svg");
      opacity: 1;
    }

    &::after {
      background-image: url("../../../../assets/icons/close-menu.svg");
      opacity: 0;
    }

    &[data-open="true"] {
      &::before {
        opacity: 0;
      }

      &::after {
        opacity: 1;
      }
    }
  }
}
