@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.select-title {
  background-color: $white;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  height: 55px;
  padding: 0;
  width: 100%;
  vertical-align: top;
  z-index: 2;

  &--title {
    align-items: center;
    display: inline-flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
    height: 100%;
    margin: 0;
    padding: 0 50px 0 27px;
    width: 100%;
    user-select: none;
    vertical-align: top;

    .icon,
    .text {
      position: relative;
    }

    .icon {
      font-size: 18px;
      color: $light_green;
      font-weight: 700;
      right: 20px;
      position: absolute;
      top: 50%;
      transition: all ease-in-out 0.5s;
      transform: translate(0, -50%) rotate(90deg);
    }

    .text {
      font-size: 18px;
      color: #777676;
      font-weight: 400;
      font-family: 'Raleway';
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &[data-active="true"] {
    .select-title {
      &--title {
        .icon {
          transform: translate(0, calc(-50% - 1px)) rotate(270deg);
        }
      }
    }
  }
}

.select-title[data-active="true"] {
  border-radius: 10px 10px 0 0;
}