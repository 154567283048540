@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.multimedia-gallery-slider-item {
  float: left;
  height: 480px;
  width: 100%;

  &--bg {
    height: 100%;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    transition: all 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    width: 100%;
    z-index: 3;

    &--img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    .icon {
      @include rem('font-size', 70px);
      color: $blue;
      left: 50%;
      position: absolute;
      top: 50%;
      transition: all 400ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
      transition-delay: 400ms;
      transform: translate(-50%, -50%);
      z-index: 5;

      &:after {
        background-color: $white;
        content: '';
        height: 30px;
        left: 40px;
        position: absolute;
        top: 20px;
        width: 30px;
        z-index: -1;
      }

      &:before {
        z-index: 2;
      }
    }

    &[data-active="true"] {
      opacity: 0;

      .icon {
        opacity: 0;
      }
    }
  }

  &--info {
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 40px 30px 20px;
    position: absolute;
    text-align: left;
    width: 100%;
    z-index: 2;

    .description {
      color: $white;
      margin: 0;
    }
  }

  &[data-type="tipo1"] {
    .multimedia-gallery-slider-item {
      &--info {
        z-index: 4;

        p {
          z-index: 4;
        }

        &::after {
          background: linear-gradient(to top, #00000080, transparent);
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 3;
        }
      }
    }

    img {
      height: 100%;
      float: left;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }

  &[data-type="tipo2"] {
    &:after {
      display: none;
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 30px;
    position: relative;

    .multimedia-gallery-slider-item {
      &--video {
        height: 100% !important;
        left: 0;
        position: absolute;
        top: 0;
        width: 100% !important;
      }
    }
  }
}