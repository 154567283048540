.time-line-slider {
  float: left;
  margin: 30px 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
  z-index: 9;
  
  .stickyroll-content {
    height: 100%;
    width: 100%;
  }
  
  &[data-active="true"] {
    .stickyroll-content {
      left: 0;
      position: fixed;
      top: 0;
    }
  }
}