@import "../../../styles/settings/colors";
@import "../../../styles/settings/fonts";

@import "../../../styles/tools/mixins";

.page-search-form {
  float: left;
  margin: 180px 0 0;
  width: 100%;

  &--title {
    display: flex;
    justify-content: center;
    margin: 0 auto 35px;
    width: 100%;

    h1 {
      @include rem("font-size", 50px);
      line-height: 50px;
      font-family: $font-default;
      font-weight: 400;
      color: #081b6a;
      text-align: center;
      margin: 0;
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width: 1024px) {
    margin: 140px 0 0;

    .page-search-form {
      &--title {
        h1 {
          @include rem("font-size", 40px);
          line-height: 40px;
        }
      }
    }
  }
}
