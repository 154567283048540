@import '../settings/fonts';
@import '../settings/colors';

@import '../tools/mixins';

.h2,
.h3,
.h4,
.paragraph{
  margin: 0 auto 35px;
  font-family: $font-default;
  font-style: normal;
  color: $blue;
}

h2,
.h2 {
  font-size: 50px;
  font-weight: 400;
  line-height: 50px;
}

h3,
.h3 {
  font-size: 36px;
  font-weight: 400;
  line-height: 36px;
  color: $dark-blue;
}

h4,
.h4 {
  font-size: 23px;
  font-weight: 600;
  line-height: 30px;
  color: $dark-blue;
}

.paragraph {
  font-size: 17px;
  font-weight: 400;
  line-height: 29px;
  color: $black;
  width: 100%;
  display: flex;
  flex-direction: column;

  &.small {
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
  }

  &.light {
    font-size: 17px;
    font-weight: 300;
  }

  &.strong {
    font-size: 17px;
    font-weight: 600;
  }

  &.title {
    font-size: 20px;
    font-weight: 300;
    line-height: 16px;
  }

  &.min {
    font-size: 14px;
    line-height: 16px;
  }

  h3 {
    font-size: 36px;
    font-weight: 400;
    line-height: 36px;
    color: $dark-blue;
    margin: 0 0 30px;
    align-self: center;
  }

  h4 {
    font-size: 23px;
    font-weight: 600;
    line-height: 30px;
    color: $dark-blue;
    margin: 0 0 30px;
    align-self: center;
  }

  a {
    color: $blue;
    cursor: pointer;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin: 0;
    color: $gray;

    &:not(:last-child) {
      margin: 0 0 35px;
    }
  }

  ul {
    list-style: none;
    margin: 0 auto 35px;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    >li {
      border: none;
      float: left;
      width: 100%;
      padding-left: 25px;
      color: $gray;

      &:before {
        content: "";
        width: 9px;
        height: 9px;
        background-color: $blue;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }
}