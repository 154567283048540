@import '../../../styles/settings/colors';

.pagination-info {
  line-height: 1em;
  overflow: hidden;
  padding: 0;
  text-align: center;
  width: fit-content;
  max-width: 65px;
  margin: 0 15px;
  display: flex;

  p {
    float: none;
    width: auto;

    &.current,
    &._,
    &.total {
      color: $dark-blue;
      margin: 0;
    }

    &.current {
      font-weight: 700;
    }
  }
}