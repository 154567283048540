.image-text-slider--image--item {
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  pointer-events: auto;
  top: 0;
  width: 100%;
  will-change: transform;

  img {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    float: left;
    height: 100%;
    object-fit: cover;
    position: relative;
    pointer-events: none;
    width: 100%;
    vertical-align: top;
  }

  &[data-type="2"] {
    > div {
      position: relative;
    }
    
    img {
      height: auto;
      width: auto;
    }
  }

  &[data-current="true"] {
    display: inline-block;

    &[data-dir="next"] {
      animation: slide-in-right 0.6s ease-out both;
    }

    &[data-dir="prev"] {
      animation: slide-in-left 0.6s ease-out both;
    }
  }

  &[data-last="true"] {
    display: inline-block;

    &[data-dir="next"] {
      animation: slide-out-left 0.6s ease-out both;
    }

    &[data-dir="prev"] {
      animation: slide-out-right 0.6s ease-out both;
    }
  }
}
