.time-line-item-slider-container {
  float: left;
  height: 100%;
  overflow: hidden;
  width: calc(100% - 55px);

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    width: 100%;
  }
}