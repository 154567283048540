@import '../../styles/settings/colors';

.title-text {
  float: left;
  text-align: justify;

  h3, h4 {
    margin: 0;
  }

  &--text {
    margin: 0;
    text-align: justify;
  }

  &--title {
    margin: 0 0 5px;
  }

  .container-element {
    text-align: justify;

    ul {
      width: 100%;
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 768px) {
    .container-element {
      ul {
        padding: 0;
      }
    }
  }
}

.title-text + .accordion {
  margin-top: -20px;
}