@import "../../../../styles/settings/colors";
@import "../../../../styles/settings/fonts";

@import "../../../../styles/tools/mixins";

.select-item-button {
  background-color: $white;
  border: none;
  border-bottom: 1px solid $gray;
  float: left;
  height: 48px;
  line-height: 1em;
  padding: 10px 20px;
  position: relative;
  text-align: left;
  width: 100%;
  user-select: none;
  vertical-align: top;

  .text {
    font-size: 18px;
    color: #777676;
    font-weight: 400;
    font-family: "Raleway";
    margin: 0;
    transition: all 400ms ease-in;
    width: 100%;
    white-space: nowrap;
  }

  &[data-active="true"] {
    .text {
      color: $white;
    }

    &:before {
      background-color: $blue;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}
