.time-line-item-slider {
  float: left;
  height: 50vh;
  max-width: 1024px;
  overflow: hidden;
  width: calc(100% - 200px);

  .slick {
    &-list,
    &-slider {
      float: left;
      width: 100%;
    }

    &-list {
      overflow: hidden;
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    height: calc(100vh - 150px);
    max-width: calc(100% - 220px);
    width: 100%;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    max-width: calc(100% - 180px);
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    max-width: none;
    width: 100%;
  }
}