.two-columns {
  max-width: 900px;
  margin: 0 auto 35px;

  .container {
    &-element {
      display: flex;
      flex-flow: row wrap;
      gap: 20px;

      .column-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: dense; 
        column-gap: 20px;
      }
    }
  }
}