.image-text-slider--image {
  height: 100%;
  left: 550px;
  overflow: hidden;
  position: absolute;
  width: calc(100% - 570px);

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    left: 0;
    height: 420px;
    position: relative;
    width: 100%;
  }
}