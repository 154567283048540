@import '../../../styles/settings/colors';

.time-line-container {
  float: left;
  width: 100%;

  .stickyroll-content {
    > div {
      &:nth-child(9n+1) {
        [class*="time-line-item--title"] {
          .icon {
            color: $orange;
          }
        }

        [class*="time-line-item-slider-button"] {
          .icon {
            color: $orange;
          }
        }
      }

      &:nth-child(9n+2) {
        [class*="time-line-item--title"] {
          .icon {
            color: #F26C26;
          }
        }

        [class*="time-line-item-slider-button"] {
          .icon {
            color: #F26C26;
          }
        }
      }

      &:nth-child(9n+3) {
        [class*="time-line-item--title"] {
          .icon {
            color: #FFC30D;
          }
        }

        [class*="time-line-item-slider-button"] {
          .icon {
            color: #FFC30D;
          }
        }
      }
  
      &:nth-child(9n+4) {
        [class*="time-line-item--title"] {
          .icon {
            color: #D3D62D;
          }
        }

        [class*="time-line-item-slider-button"] {
          .icon {
            color: #D3D62D;
          }
        }
      }
  
      &:nth-child(9n+5) {
        [class*="time-line-item--title"] {
          .icon {
            color: #00EDCB;
          }
        }

        [class*="time-line-item-slider-button"] {
          .icon {
            color: #00EDCB;
          }
        }
      }
  
      &:nth-child(9n+6) {
        [class*="time-line-item--title"] {
          .icon {
            color: #009FE3;
          }
        }

        [class*="time-line-item-slider-button"] {
          .icon {
            color: #009FE3;
          }
        }
      }
  
      &:nth-child(9n+7) {
        [class*="time-line-item--title"] {
          .icon {
            color: #0056A1;
          }
        }

        [class*="time-line-item-slider-button"] {
          .icon {
            color: #0056A1;
          }
        } 
      }
  
      &:nth-child(9n+8) {
        [class*="time-line-item--title"] {
          .icon {
            color: #23305A;
          }
        }

        [class*="time-line-item-slider-button"] {
          .icon {
            color: #23305A;
          }
        }
      }
  
      &:nth-child(9n+9) {
        [class*="time-line-item--title"] {
          .icon {
            color: $dark_orange;
          }
        }

        [class*="time-line-item-slider-button"] {
          .icon {
            color: $dark_orange;
          }
        }
      }
    }
  }
}