@import '../../../../styles/settings/colors';

.header-menu-submenu {
  width: 350px;
  height: auto;
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: baseline;
  gap: 25px;
  border-radius: 3px;
  background-color: $white;
  box-shadow: 4px 4px 4px #0000001A;
  left: -58px;
  padding: 33px 58px;
  position: absolute;
  top: 75%;
  cursor: auto;

  @media only screen and (max-width : 1024px) {
    gap: 0;
    background-color: $light_blue;

    .header-menu-submenu-item {
      &--link {
        .text {
          color: $dark_blue;
        }
      }
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    padding: 0;
    position: relative;
    top: initial;
    width: 100%;

    &[data-active="true"] {
      .link {
        .text {
          font-weight: 600;
        }
      }
    }
  }
}