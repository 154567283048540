@import '../../../styles/settings/colors';

.webdoor-controls {
  width: 100%;
  position: absolute;
  pointer-events: auto;
  bottom: 0;
  display: flex;
  justify-content: center;
  gap: 12px;

  &--btn {
    height: 13px;
    float: left;
    padding: 0;
    width: 13px;
    
    &.active {
      background-color: $white;
    }
    
    &.btn {
      border: 1px solid $white;
      border-radius: 100%;
    }
  }
}