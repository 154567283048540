/* --- GRID --- */
@import "./colors";
@import "./fonts";

html,
body,
body * {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  vertical-align: top;
}

html,
body {
  float: left;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  vertical-align: top;
}

html {
  overflow: hidden;
  overflow-y: auto;
}

body {
  height: auto;
  font-family: $font-default;
  min-height: 100vh;
  overflow: hidden;
  overflow-anchor: none;
  width: 100%;

  a {
    text-decoration: none;
  }

  .main {
    float: left;
    width: 100vw;
  }

  script,
  style,
  link {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    margin-top: 88px;
  }
}

#root {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  .home {
    @media only screen and (min-width: 1024px) {
      header {
        z-index: 999;
        background-color: transparent;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 220%;
          max-height: 280px;
          background: linear-gradient(180deg, #30425099 2%, transparent);
          z-index: -1;
        }

        .header-navigation {
          .header-top-submenu {
            .header-top-menu {
              &--item {
                &--link {
                  .paragraph {
                    color: $white;
                  }
                }
              }
            }
          }

          .header-menu {
            border-color: rgba(247, 247, 247, 0.3);

            .header-menu-item {
              &--link {
                .paragraph {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}
