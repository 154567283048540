@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.time-line-item {
  display: none;
  left: 0;
  height: 100vh;
  top: 147px;
  pointer-events: none;
  position: absolute;
  width: 100%;

  &--bg {
    overflow: hidden;
    height: 100%;
    width: 100%;
    background-color: $blue;
    z-index: 2;

    &:has(.time-line-item--image) {
      &:before {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
        content: '';
        height: 70vh;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        transform: matrix(1, 0, 0, -1, 0, 0);
        transition: all 400ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
        width: 100%;
        z-index: 4;
      }
    }
  }

  &--image {
    overflow: hidden;
    height: 100%;
    width: 100%;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &--info {
    left: 0;
    position: absolute;
    top: 0;
    transition: all 400ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    width: 100%;
  }

  &--title {
    float: left;
    margin: 20px 0 10px;
    padding: 24px 0 0 45px;
    width: 100%;
    z-index: 3;

    &::before {
      content: url('../../../assets/icons/image-text-icon-2.svg');
      position: absolute;
      top: 10px;
      left: -10px;
      rotate: 265deg;
      scale: 0.55;
    }

    .date,
    .label {
      color: $white;
      float: left;
      width: 100%;
      line-height: 0.9em;
    }
    
    .icon {
      @include rem('font-size', 35px);
      color: $orange;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }

    .date {
      @include rem('font-size', 30px);
      font-weight: 300;
    }

    .label {
      @include rem('font-size', 14px);
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  > div {
    float: left;
    width: 100%;
    z-index: 5;
  }

  &[data-active="true"] {
    pointer-events: auto;
    z-index: 3;

    .time-line-item {
      &--bg {
        &:before {
          opacity: 1;
        }
      }
    }
  }

  &[data-active="false"] {
    z-index: -1;
  }

  &[data-last="true"] {
    z-index: 2;
    
    .time-line-item {
      &--bg {
        display: inline-block;
        transform: translate(0, 0) !important;
      }
    }
  }

  &[data-active="true"] {
    display: inline-block;

    &[data-dir="next"] {
      animation: slide-in-bottom 0.6s cubic-bezier(0.38, 0.4, 0.58, 1) both;
    }

    &[data-dir="prev"] {
      animation: slide-in-top 0.6s cubic-bezier(0.38, 0.4, 0.58, 1) both;
    }

    &[data-init="true"] {
      animation: none;
    }
  }

  &[data-last="true"] {
    display: inline-block;
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1140px) {
    top: 55px;

    .time-line-item {
      &--title {
        margin-top: 30px;
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    .time-line-item {
      &--info {
        padding: 75px 0 0;
        z-index: 7;
      }
    }

    &:before {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5625) 40.63%, rgba(0, 0, 0, 0.9) 100%);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 6;
    }
  }
}