@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.multimedia-gallery-slider-arrow {
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  transition: all 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  width: 70px;
  z-index: 2;

  svg {
    max-width: 30px;

    path {
      fill: $light_blue;
    }
  }

  &[data-type="prev"] {
    left: -70px;

    svg {
      transform: rotate(180deg);
    }
  }

  &[data-type="next"] {
    right: -70px;
  }

  &[data-disabled='true'] {
    pointer-events: none;

    svg {
      filter: grayscale(0.8);
      opacity: 40%;
    }
  }

  &::before {
    background-color: $dark_blue;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    display: none;
  }
}