/* --- FONTS --- */

@font-face {
    font-family: 'Raleway';
    src: url('../../assets/fonts/Raleway-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('../../assets/fonts/Raleway-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('../../assets/fonts/Raleway-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('../../assets/fonts/Raleway-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* --- VARS --- */

$font-base-size: 16px;
$font-default: 'Raleway', 'sans-serif';
