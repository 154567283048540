@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.not-found {
  float: left;
  width: 100%;
  margin-top: 179px;
  flex: 1;

  .container {
    &-element {
      margin: 0 auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      .not-found {
        &-subtitle {
          max-width: 800px;
          margin: 0 auto 35px;
          @include rem('font-size', 25px);
          font-weight: 400;
          line-height: 42.5px;
          color: $orange;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;
        }

        &-group {
          max-width: 700px;
          width: 100%;
          padding: 30px 53px 40px 53px;
          border: 1px solid $light_blue;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;

          &-list {
            list-style: none;
            padding: 0;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 25px;

            li {
              @include rem('font-size', 18px);
              line-height: 25.2px;
              text-align: center;
              display: flex;
              flex-direction: column;

              strong {
                font-weight: 600;
                color: $light_blue;
              }

              p {
                margin: 0;
                color: $black;
              }
            }
          }
        }
      }
    }
  }

}