.page {
  float: left;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  &:has(.related-content-list) {
    &+footer {
      margin-top: 90px;
    }
  }
}