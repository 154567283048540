@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.highlight-banner-with-icon-content-item {
  display: flex;
  flex-direction: column;
  max-width: 288px;
  width: 100%;
  height: auto;
  padding: 32px 0 0;
  position: relative;

  .image {
    max-width: 125px;
    max-height: 125px;
    object-fit: contain;
    position: absolute;
    top: 0;
    right: 0;
  }

  strong {
    font-family: 'Raleway';
    font-size: 50px;
    line-height: 50px;
    font-weight: 500;
    color: $black;
    text-align: center;
    margin-bottom: 16px;
  }

  .paragraph {
    p {
      font-family: 'Raleway';
      font-size: 28px;
      line-height: 31px;
      font-weight: 400;
      color: $black;
      text-align: center;
      margin: 0;
    }
  }
}