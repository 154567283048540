@import '../../../../styles/settings/colors';

.header-top-logo {
  float: left;

  .logo {
    float: left;

    svg {
      transition: all 400ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
      width: 230px;

      .st0{
        fill: $blue;
      }

      .st1{
        fill: $dark_orange;
      }
    }
  }

  &[data-min="true"] {
    .logo {
      svg {
        top: 3px;
        width: 139px;
      }
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    .logo {
      svg {
        width: 156px;
        height: 47px;
      }
    }

    &[data-min="true"] {
      .logo {
        svg {
          top: 0;
          width: 145px;
        }
      }
    }
  }
}