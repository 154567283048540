@import '../../styles/settings/colors';

.single-image {
  float: left;
  margin: 0 auto 40px;
  text-align: center;
  width: 100%;

  .container-element {
    padding: 0;
    text-align: center;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .container {
    float: left;
    padding: 0 20px;
    width: 100%;
  }

  &--icon {
    bottom: -0px;
    display: none;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0);
    z-index: 2;

    svg {
      animation: left-right 4s cubic-bezier(0.23, 1, 0.320, 1) 0s infinite forwards;
      right: -3px;
      width: 20px;
    }

    &:after,
    &:before {
      content: '';
      position: absolute;
    }
    
    &:before {
      animation: left-right-l 4s cubic-bezier(0.23, 1, 0.320, 1) 0s infinite forwards;
      background-color: $white;
      border-radius: 100%;
      left: 0%;
      height: 4px;
      transform: translate(10px, 0);
      top: -9px;
      width: 4px;
      z-index: 2;
    }

    &:after {
      background-color: $blue;
      border-radius: 4px;
      border: 1px solid $blue;
      height: 6px;
      left: -4px;
      top: -11px;
      transform: translate(12px, 0);
      width: calc(100% + 5px);
      z-index: 1;
    }
  }

  img {
    float: none;
    position: relative;
    max-width: 876px;
    max-height: 439px;
    border-radius: 5px;
    object-fit: cover;
  }

  &[data-size="true"] {
    padding-bottom: 50px;

    .single-image {
      &--icon {
        display: inline-block;
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 480px) {
    .container {
      padding: 0;
    }
  }

  @keyframes left-right-l {
    0% {
      left: 0%;
    }
    50% {
      left: 100%;
    }
    100% {
      left: 0%;
    }
  }

  @keyframes left-right {
    0% {
      transform: translate(0%, 0);
    }
    50% {
      transform: translate(100%, 0);
    }
    100% {
      transform: translate(0%, 0);
    }
  }
}