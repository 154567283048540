@import "../../../styles/settings/colors";

.select {
  pointer-events: auto;
  position: relative;
  vertical-align: top;
  width: 100%;
  z-index: 1;

  &--wrapper {
    width: 100%;
    height: 100%;
    position: relative;

    .required-input {
      color: red;
      font-weight: 700;
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 9;
    }
  }

  &--list {
    background-color: $white;
    border: 1px solid #d6d6d6;
    border-radius: 0 0 10px 10px;
    display: none;
    left: 0;
    max-height: 300px;
    margin: 0;
    overflow: hidden;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    vertical-align: top;
    z-index: 1;

    &--item {
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    &:before {
      background-color: $white;
      border-radius: 0;
      content: "";
      height: 8px;
      left: 0;
      position: absolute;
      top: 100%;
      width: 100%;
    }
  }

  &[data-active="true"] {
    z-index: 99999;

    .select {
      &--list {
        display: inline-block;
      }
    }

    > button {
      border-radius: 10px 10px 0 0;
      border-bottom-color: transparent;
    }
  }
}
