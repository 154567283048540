@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.news {
  float: left;
  width: 100%;
  max-height: 420px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:before {
    color: $yellow;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    content: 'Saiba mais';
    width: 155px;
    position: absolute;
    left: 20px;
    bottom: 18px;
    z-index: 1;
  }

  &--image {
    float: left;
    height: 178px;
    width: 100%;

    img {
      float: left;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}