@import '../../../styles/settings/colors';

.header-menu {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  transition: all 400ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  width: 100%;

  .container-element {
    padding: 0;
  }

  div[class*="header-menu-item"] {
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;

      &:before {
        display: none;
      }

      .header-menu-submenu {
        left: unset;
        right: 0;

        @media only screen and (min-width : 1024px) {
          .header-menu-submenu-item {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: $white;
    position: absolute;
    top: 0;
    opacity: 20%;
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    background-color: #F7F7F7;
    left: initial;
    height: auto;
    margin: 0;
    position: relative;
    width: 100%;
    margin-top: 42px;

    .container-element {
      padding: 0;
    }

    div[class*="header-menu-item"] {
      margin: 0;
      width: 100%;
    }

    &[data-min="true"] {
      .header-menu--item {
        > div {
          padding: 0;
        }
      }
  
      &:before {
        display: none;
        height: 0;
      }
    }
  }

  /* Medium Devices, Phones */
  @media only screen and (max-width : 370px) {
    max-width: 100vw;
  }
}

.header-navigation {
  .container-element {
    .header-menu {
      .container-element {
        flex-direction: row;

        @media only screen and (max-width : 1024px) {
          flex-direction: column;
        }
      }
    }
  }
}
