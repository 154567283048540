@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.table {
  width: 100%;
  margin: 0 auto 40px;

  .container {    
    .container-element {
      max-width: none;
      width: calc(100% - 40px);
      padding: 0 0 20px;
      overflow: hidden;
      @media only screen and (max-width: 940px) {
        overflow: scroll;
      }
      &::-webkit-scrollbar {
        width: 0;
        height: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background: $dark_blue;
      }
      &::-webkit-scrollbar-track {
        background: $light_gray;
      }
    }
  }

  table {
    min-width: 900px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-collapse: collapse;
    gap: 10px;

    caption {
      font-size: 13px;
      line-height: 18px;
      order: 1;
    }

    thead {
      display: table;
      width: 100%;

      tr {
        &:first-child {
          border-radius: 5px 5px 0 0;
        }
        
        th, td {
          padding: 10px 15.5px;
          line-height: 21px;
        }
      }
    }

    &:not(:has(thead)) {
      tbody {
        tr {
          &:first-child {
            border-radius: 5px 5px 0 0;
          }
        }
      }
    }

    tbody {
      display: table;
      width: 100%;

      tr {
        border-top: none;

        th, td {
          padding: 10px 14px;
        }

        &:nth-child(even) {
          background-color: $light_gray;
        }

        &:last-child {
          border-radius: 0 0 5px 5px;
        }
      }
    }

    tr {
      border: 1px solid #E4E4E4;
      display: flex;
      margin: 0;
      text-align: left;
      width: 100%;
      overflow: hidden;

      &:not(:first-child) {
        border-top: none;
      }

      td,
      th {
        min-width: 300px;
        font-size: 16px;
        line-height: 24px;
        flex: 1;
      }

      th {
        background-color: $dark_blue;
        color: $white;
      }

      td {
        color: $gray;
      }
    }
  }
}

.containerMobile {
  &:first-child {
    margin-top: 3rem;
  }

  &:nth-child(4n) {
    border-bottom: 1px solid #cfcfcf;
    padding-bottom: 2rem;
  }

  .title {
    display: flex;
    color: $blue;
    text-transform: uppercase;

    &:first-child {
      padding-top: 0.8rem;
    }

    &:nth-child(5n) {
      padding-top: 2rem;
    }

      &:before {
        background-color: $orange;
        content: "";
        height: 23px;
        margin: 0 15px -10px 0;
        position: relative;
        transform: skewX(-27deg);
        top: 0;
        width: 5px;
    }
  }

  .text {
    font-size: 1rem;
    color: #434343;
    font-weight: 400;
    width: 90%;
    margin: -0.5rem auto 0 auto;

    &:first-child {
      font-weight: 700 ;
    }
  }
}