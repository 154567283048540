@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.board-management-item-modal {
  height: 100vh;
  left: 0;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999;
  
  &--content,
  &--title {
    width: 100%;
  }
  
  &--container {
    background-color: $white;
    border: 1px solid $blue;
    border-radius: 5px;
    max-width: 740px;
    left: 50%;
    padding: 30px 50px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 1;
  }

  &--content {
    padding: 5px 0 0;
    
    > p {
      margin: 0;
    }
  }

  &--title {
    background-color: $white;
    z-index: 2;

    .btn {
      right: -10px;
      height: 15px;
      padding: 0;
      position: absolute;
      top: 0;
      transition: rotate ease-in 0.2s;

      .icon {
        @include rem('font-size', 15px);
        color: $orange;
      }

      &:hover {
        rotate: 90deg;
      }
    }

    .role,
    .title {
      text-align: left;
      margin: 0;
    }

    .role {
      color: $orange;
      font-weight: 700;
    }

    .paragraph.title {
      font-weight: 400;
      color: $light_blue;
    }
  }

  &:before {
    background-color: $black;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.9;
    position: absolute;
    top: 0;
    width: 100%;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .board-management-item-modal {
      &--container {
        max-height: calc(100vh - 120px);
        height: auto;
        padding: 30px;
        overflow: hidden;
        overflow-y: auto;
      }

      &--title {
        left: 0;
        top: 0;
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    padding: 20px;
  }
}