@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.news-list {
  float: left;
  width: 100%;
  margin: 0 auto 70px;

  &--loader {
    display: none;
    float: left;
    padding: 190px 0;
    width: 100%;

    .loader,
    .loader:after {
      border-radius: 50%;
      width: 2em;
      height: 2em;
    }

    .loader {
      @include rem('font-size', 15px);
      animation: spinner-loader 1.1s infinite linear;
      border-top: 0.4em solid $orange;
      border-right: 0.4em solid $orange;
      border-bottom: 0.4em solid $orange;
      border-left: 0.4em solid $blue;
      margin: 0;
      left: calc(50% - 14px);
      position: absolute;
      text-indent: -9999em;
      top: calc(50% - 15px);
      transform: translateZ(0);
      z-index: 1;
    }

    &[data-active="true"] {
      display: inline-block;
    }

    &:after {
      background-color: $blue;
      border-radius: 2px;
      content: '';
      left: 50%;
      height: 48px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 48px;
      z-index: 0;
    }
  }

  .container-element {
    padding: 0;
  }

  &[data-loading="true"] {
    .container-element {
      display: none;
    }

    div[data-type="loader"] {
      display: inline-block;
    }
  }
}
