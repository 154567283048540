.webdoor-content {
  height: 100%;
  left: 0;
  position: absolute;
  pointer-events: auto;
  top: 0;
  width: 100%;
  z-index: 2;
  user-select: none;

  &:before {
    background: linear-gradient(0deg, rgba(0, 46, 89, 0.15), rgba(0, 46, 89, 0.15));
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    pointer-events: none;
    top: 0;
    width: 100%;
    z-index: 2;
  }
}