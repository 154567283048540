@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.board-management-item {
  float: left;
  height: 420px;
  max-width: 420px;
  width: 100%;

  &--btn {
    left: 0;
    padding: 5px 10px;
    position: absolute;
    top: 0;

    svg {
      width: 38px;
    }
  }

  &--info {
    bottom: 0;
    left: 0;
    padding: 10px 20px;
    position: absolute;
    text-align: center;
    width: 100%;

    .role,
    .title {
      margin: 0;
      line-height: 1em;
      width: 100%;
    }

    .role {
      @include rem('font-size', 14px);
      font-weight: 700;
      line-height: 22px;
      color: $light_blue;
    }

    .title {
      @include rem('font-size', 20px);
      line-height: 22px;
      color: $white;
    }

    &:before {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.63) 100%);
      bottom: 0;
      content: '';
      height: 175px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  .image {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    max-width: 100%;
  }
}