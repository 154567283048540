@import "../../../styles/settings/colors";

.form-contact {
  width: 100%;
  height: auto;

  &--button {
    width: auto;
    height: 38px;
    background-color: $light_green;
    border: none;
    border-radius: 20px;
    padding: 0 32px;
    color: $dark_blue;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color ease-in 0.3s, color ease-in 0.3s;

    &:hover {
      background-color: $green;
    }

    &[disabled] {
      cursor: default;
      opacity: 0.8;
    }
  }

  .container-element {
    max-width: 100% !important;
    padding: 0;
    text-align: center;
  }

  &--container {
    width: 100%;
    max-width: 764px;
    height: auto;
    padding: 30px;
    background-color: $light_gray;
    border-radius: 5px;
    margin: 30px auto 70px;

    form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-family: "Raleway";

      &::before {
        display: none;
      }

      .required {
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        color: $gray;
        text-align: left;
      }

      .textarea-title {
        font-size: 25px;
        line-height: 26px;
        font-weight: 400;
        color: $dark_blue;
        text-align: left;
        margin-top: 20px;
        margin-bottom: -8px;
      }

      &[data-submitting="true"] {
        &:before,
        &:after {
          content: "";
          position: absolute;
          z-index: 2;
        }

        &:before {
          animation: animation-loading 2s ease-in-out infinite;
          background-color: $green;
          border-radius: 100%;
          height: 40px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          transform-origin: left top;
          width: 40px;
          z-index: 3;
        }

        &:after {
          background-color: $white;
          left: 0;
          height: 100%;
          opacity: 0.5;
          top: 0;
          width: 100%;
        }
      }

      @keyframes animation-loading {
        0%,
        100% {
          transform: scale(0.25) translate(-50%, -50%);
          opacity: 1;
        }

        50% {
          transform: scale(1) translate(-50%, -50%);
          opacity: 0;
        }
      }
    }
  }

  &--footer {
    margin: 20px 0 0;
    width: 100%;
  }

  .form-file {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    input {
      width: 100%;
      height: 55px;
      border: 1px solid #d6d6d6;
      border-radius: 10px;
      background-color: $white;
      padding: 16px;
      width: 100%;
    }

    .form-file-error {
      display: none;
      font-size: 18px;
      color: red;
      font-weight: 400;
      font-family: "Raleway";
      text-align: left;
      margin-top: 5px;
    }
  }

  .form-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;

    input {
      margin: 0;

      &:hover {
        cursor: pointer;
      }
    }

    p {
      font-size: 18px;
      color: #777676;
      font-weight: 400;
      font-family: "Raleway";
      text-align: justify;
      margin: -3px 0 0;

      a {
        color: $blue;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .loading-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: $blue;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    border-radius: 5px;

    .spinner {
      width: 32px;
      height: 32px;
      border: 4px solid $light_green;
      border-top-color: $yellow;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }

  @media only screen and (max-width: 1023px) {
    padding: 0 20px;
  }

  @media only screen and (max-width: 767px) {
    &--container {
      padding: 30px 10px;
    }
  }
}
