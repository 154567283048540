.image-text-slider--controls {
  height: 100%;
  left: 0;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    bottom: 0;
    height: 80px;
    top: initial;
  }
}