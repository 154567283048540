@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.card-image-title-text-item {
  display: flex;
  flex-direction: column;
  max-width: 348px;
  width: 100%;
  min-height: 357px;
  padding: 30px 20px 20px;
  background-color: $white;
  border-radius: 5px;
  border: 1px solid #E4E4E4;

  .image {
    width: 308px;
    height: 108px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 16px;
  }

  &-title {
    font-family: 'Raleway';
    font-size: 20px;
    line-height: 23px;
    font-weight: 600;
    color: $dark_blue;
    text-align: left;
    margin-bottom: 8px;
  }

  &-description {
    font-family: 'Raleway';
    font-size: 17px;
    line-height: 25px;
    font-weight: 400;
    color: #484848;
    text-align: left;
    margin: 0 !important;

    b {
      font-weight: 700;
    }

    a {
      color: $blue !important;
      transition: all ease-in-out 0.4s;

      &:hover {
        color: $green !important;
        text-decoration: none !important;
      }
    }
  }
}