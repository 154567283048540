@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.loading {
  background-color: $white;
  left: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999;

  .loader,
  .loader:after {
    border-radius: 50%;
    width: 2em;
    height: 2em;
  }

  .loader {
    font-size: 15px;
    animation: spinner-loader 1.1s infinite linear;
    border-top: 0.4em solid $yellow;
    border-right: 0.4em solid $yellow;
    border-bottom: 0.4em solid $light_green;
    border-left: 0.4em solid $light_green;
    margin: 0;
    left: calc(50% - 15px);
    position: absolute;
    text-indent: -9999em;
    top: calc(50% - 15px);
    transform: translateZ(0);
    z-index: 1;
  }

  &[data-active="true"] {
    display: inline-block;
  }

  &:after {
    background-color: $blue;
    border-radius: 2px;
    content: '';
    left: 50%;
    height: 48px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 48px;
    z-index: 0;
    border-radius: 5px;
  }
}