.webdoor-list {
  height: 900px;
  pointer-events: auto;
  width: 100vw;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 45%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0.55%, rgba(0, 0, 0, 0.8) 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9;
  }

  > div {
    &[class*=container] {
      left: 0;
      height: 100%;
      top: 50%;
      pointer-events: none;
      position: absolute;
      transform: translate(0, -50%);
      z-index: 13;
    }
  }

  .container-element {
    align-items: flex-end;
    display: inline-flex;
    justify-content: center;
    flex-flow: row wrap;
    height: 100%;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 1024px) {
    height: 505px;
    max-height: none;

    .container-element {
      padding: 0;

      > button {
        display: none;
      }
    }

    > div {
      &[class*=container] {
        left: initial;
        position: relative;
        top: initial;
        transform: none;
      }
    }
  }
}