@import "../../styles/settings/colors";
@import "../../styles/settings/fonts";

@import "../../styles/tools/mixins";

.home {
  width: 100%;
  float: left;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  
  .page {
    margin-top: 0;
  }
}
