@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.header-top-submenu-mobile {
  width: 100%;
  align-items: center;
  display: flex;
  float: right;
  text-align: right;

  .header-top-submenu-contact {
    margin: 0;
    border-radius: 0;
  }

  .btn-email {
    background-color: transparent;
    margin: 0;
    padding: 20px;
    text-decoration: none;
    text-align: left;
    width: 100%;

    .icon,
    svg {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }

    svg {
      left: 20px;
      width: 20px;
    }

    .icon {
      @include rem('font-size', 18px);
      color: $blue;
      right: 25px;
    }

    .text {
      @include rem('font-size', 16px);
      color: $blue;
      float: left;
      font-weight: 600;
      margin: 0;
      padding: 0 0 0 30px;
      text-transform: capitalize;
      width: 100%;
    }

    &:before {
      background-color: $light_blue;
      content: '';
      height: 100%;
      left: 0;
      opacity: 0.15;
      top: 0;
      position: absolute;
      width: 100%;
    }
  }

  .btn-open {
    float: right;
    height: 20px;
    padding: 3px;
    width: 20px;

    .icon {
      color: $blue;
      left: 0;
      top: 50%;
      position: absolute;
      transform: translate(0, -50%);

      &:last-child {
        display: none;
      }
    }

    &[open] {
      .icon {
        display: none;

        &:last-child {
          display: inline-block;
        }
      }
    }
  }

  > a {
    margin: 0 15px;
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    display: inline-block;
    float: none;
    position: unset;

    .btn-email {
      border: none;
      border-bottom: 1px solid $light_blue;
    }
  }
}