@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.page-news {
  float: left;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  > div[data-type="2"] {
    float: left;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    flex: 1;

    .container-element {
      margin: 0 auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      .h2 {
        margin: 0;
      }

      .date {
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: $gray;
      }

      .category {
        width: fit-content;
        background-color: $yellow;
        padding: 4px 6px 2px;
        margin-bottom: 26px;
        border-radius: 5px;
        font-weight: 700;
        line-height: 18px;
        color: $gray;
        text-transform: uppercase;
      }

      .title {
        margin: 0 auto 40px;
        font-weight: 400;
        font-size: 30px;
        line-height: 30px;
      }

      .page {
        margin-top: 0;
      }

      .body {
        text-align: justify;
        width: 100%;
      }
  
      .link-button {
        margin-top: 40px;
      }
    }
  }
}