@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.image-text-slider--controls--button {
  padding: 0;
  position: absolute;
  pointer-events: auto;
  top: initial;
  bottom: 40px;

  &:disabled {
    pointer-events: none;
    filter: grayscale(0.8);

    svg {
      opacity: 40%;
    }
  }

  &[data-type="prev"] {
    left: 45px;
    top: initial;
    scale: 0.5;
    transform: none;
    
    svg {
      transform: rotate(180deg);
    }
  }

  &[data-type="next"] {
    right: 45px;

    svg {
      path {
        transition: all ease-in 0.2s;
      }
    }

    &:hover {
      svg {
        path {
          fill: $light_blue;
        }
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    &[data-type="prev"] {
      left: 17px;
    }

    &[data-type="next"] {
      scale: 0.8;

      &:hover {  
        svg {
          path {
            fill: $white
          }
        }
      }
    }
  }

  @media only screen and (max-width : 420px) {
    &[data-type="next"] {
      right: 30px;
      scale: 0.6;
    }
  }
}