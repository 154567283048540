@import "../../../../styles/settings/colors";
@import "../../../../styles/settings/fonts";

@import "../../../../styles/tools/mixins";

.page-search-result-pagination {
  width: 100%;

  &--title {
    border-bottom: 1px solid #cfcfcf;
    margin: 0;
    width: 100%;

    .number,
    .text {
      @include rem("font-size", 17px);
      line-height: 29px;
      font-family: $font-default;
      font-weight: 600;
      color: #081b6a;
      display: inline-block;
      margin: 0 0 8px;
    }

    .number {
      font-weight: 700;
      margin-right: 5px;
    }
  }
}
