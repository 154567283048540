@import '../../../styles/settings/colors';

.header-menu-mobile {
  background-color: #FFFFFF;
  height: calc(100vh - 55px);
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  top: 88px;
  max-width: 420px;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 9999;

  &[data-active="true"] {
    animation: slide-in-left 0.3s cubic-bezier(0.38, 0.4, 0.58, 1) both;
    pointer-events: auto;
  }

  &[data-active="false"] {
    animation: slide-out-left 0.2s cubic-bezier(0.38, 0.4, 0.58, 1) both;
    pointer-events: none;
  }

  /* Medium Devices, Phones */
  @media only screen and (max-width : 370px) {
    max-width: 100vw;
  }
}