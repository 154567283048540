@import '../../styles/settings/colors';

.image-text-slider {
  float: left;
  margin: 0 auto 70px;
  transition: all 400ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  width: 100%;
  z-index: 8;

  .container-element {
    padding: 0 20px 110px;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .container-element {
      padding: 0 20px;
    }
  }
}