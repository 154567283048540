.news-list-content {
  float: left;
  padding: 0 20px;
  width: 100%;

  &--container {
    float: left;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px 20px;
    margin: 0 auto 35px;
    width: 100%;

    /* Small Devices, Tablets */
    @media only screen and (max-width : 768px) {
      grid-template-columns: 1fr 1fr;
    }

    /* Custom, iPhone Retina */ 
    @media only screen and (max-width : 480px) {
      grid-template-columns: 1fr;
    }
  }

  .container-element {
    text-align: center;
  }
}