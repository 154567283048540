@import "../../../../styles/settings/colors";
@import "../../../../styles/settings/fonts";

@import "../../../../styles/tools/mixins";


li {
    display: flex;
    flex-flow: column wrap;

    .title {
        @include rem('font-size', 17px);
        font-weight: 700;
        line-height: 25.86px;
        color: $orange;
    }

    .paragraph {
        margin-bottom: 20px;
    }

    &[data-column='1'] {
        grid-column-start: 1;
    }

    &[data-column='2'] {
        grid-column-start: 2;
    }
}