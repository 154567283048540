@import "../../../styles/settings/colors";
@import "../../../styles/settings/fonts";

@import "../../../styles/tools/mixins";

.page-search-input {
  margin: 0 auto 20px;
  position: relative;
  width: 100%;

  .input-search {
    background-color: $white;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    outline: none;
    padding: 10px 50px 10px 20px;
    width: 100%;
    height: 48px;
    @include rem("font-size", 16px);
    color: #da4252;
    font-style: italic;
    line-height: 26px;
    font-weight: 600;
    font-family: $font-default;

    &::placeholder {
      @include rem("font-size", 16px);
      color: #da4252;
      font-style: italic;
      line-height: 26px;
      font-weight: 600;
      font-family: $font-default;
    }
  }

  button {
    cursor: pointer;
    right: 10px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    .icon {
      @include rem("font-size", 18px);
      color: $orange;
    }
  }
}
