@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.news-info {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  &--year, 
  &--description {
    float: left;
    width: 100%;
  }

  &--year {
    background-color: $blue;
    padding: 15px 20px 0;

    .text {
      font-size: 14px;
      color: $white;
      float: left;
      font-weight: 600;
      margin: 0;
      width: 100%;
    }
  }

  &--content {
    width: 100%;
    background-color: $blue;
    padding: 14px 20px 46px;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 12px;

    .news-info--category {
      @include rem('font-size', 15px);
      font-weight: 700;
      line-height: 18px;
      color: $light_blue;
      text-transform: uppercase;
    }

    .news-info--description {
      .description {
        float: left;
        width: 100%;
        margin: 0;
        @include rem('font-size', 16px);
        font-weight: 400;
        line-height: 20.8px;
        color: $white;
        overflow: hidden;

        p {
          color: $white;
          font-size: 18px;
        }
      }
    }
  }
}