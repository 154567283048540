@import './../../styles/settings/colors';
@import './../../styles/settings/fonts';

@import './../../styles/tools/mixins';

.highlight-banner-with-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  height: auto;
  min-height: 330px;
  padding: 88px 20px;
  background-color: $yellow;

  &-title {
    font-family: 'Raleway';
    font-size: 60px;
    line-height: 60px;
    font-weight: 600;
    color: $black;
    text-align: center;
  }

  &-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 78px;
    max-width: 1386px;
    width: 100%;
    height: auto;
  }

  //Responsivo
  @media only screen and (max-width : 1365px) {
    .highlight-banner-with-icon-content {
      max-width: 1020px;
    }
  }

  @media only screen and (max-width : 1023px) {
    .highlight-banner-with-icon-content {
      max-width: 654px;
    }
  }

  @media only screen and (max-width : 767px) {
    .highlight-banner-with-icon-content {
      flex-direction: column;
      gap: 50px;
      max-width: 288px;
    }
  }
}