@import "../../../../styles/settings/colors";
@import "../../../../styles/settings/fonts";

@import "../../../../styles/tools/mixins";

.input-group {
  width: 100%;

  .input-form {
    &[data-empty="true"] {
      .label {
        font-size: 18px;
        color: #777676;
        font-weight: 400;
        font-family: 'Raleway';
        top: 29px;
        left: 16px;
      }
    }

    &[data-empty="false"] {
      .label {
        display: none;
      }
    }
  }
}