.pagination {
  float: left;
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  .container-element {
    align-items: center;  
    display: inline-flex;
    justify-content: center;
    flex-flow: row wrap;
    text-align: center;
  }
}