@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.time-line-controls-item {
  background-color: $orange;
  cursor: pointer;
  float: left;
  padding: 25px 0 28px;
  pointer-events: auto;
  width: 100%;
  
  .text {
    @include rem('font-size', 30px);
    color: $white;
    float: left;
    line-height: 1em;
    font-weight: 700;
    margin: 0;
  }

  &:before {
    background-color: $white;
    bottom: 0;
    content: '';
    height: 6px;
    right: 0;
    position: absolute;
    transition: all 400ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    width: 0;
    z-index: 2;
  }

  &[data-active="true"] {
    &:before {
      width: calc(100% + 20px);
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    &[data-active="true"] {
      &:before {
        width: calc(100% + 10px);
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 17px 0 18px;
    
    .text {
      @include rem('font-size', 25px);
      font-weight: 600;
    }

    &:before {
      height: 4px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    .text {
      @include rem('font-size', 20px);
    }

    &[data-active="true"] {
      &:before {
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
      }
    }
  }
}