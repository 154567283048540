/* --- ICONS --- */
%icon,
[class^="icon-"],
[class*=" icon-"],
.icon {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right:before {
  content: "\e90e";
}
.icon-file-pdf:before {
  content: "\e907";
}
.icon-file:before {
  content: "\e90c";
}
.icon-video:before {
  content: "\e90d";
}
.icon-arrow-dg:before {
  content: "\e900";
}
.icon-arrow-down:before {
  content: "\e901";
}
.icon-facebook:before {
  content: "\e902";
}
.icon-hamburguer:before {
  content: "\e903";
}
.icon-open:before {
  content: "\e904";
}
.icon-text-dg:before {
  content: "\e905";
}
.icon-warning:before {
  content: "\e906";
}
.icon-twitter:before {
  content: "\e908";
}
.icon-times:before {
  content: "\e90a";
}
.icon-checked:before {
  content: "\e914";
}
.icon-linkedin:before {
  content: "\e90b";
}
.icon-search:before {
  content: "\e909";
}
.icon-play:before {
  content: "\ea1c";
}
.icon-pause:before {
  content: "\ea1d";
}
.icon-volume-medium:before {
  content: "\ea27";
}
.icon-volume-mute:before {
  content: "\ea2a";
}
.icon-instagram:before{
  content: "\e91a"
}
