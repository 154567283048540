@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.news-slider-control-button {
  padding: 0;
  position: absolute;
  bottom: -5px;
  z-index: 2;
  transition: all ease-in 0.3s;

  svg {
    width: 28px;
    height: 20px;

    path {
      fill: $light_blue;
    }
  }

  &.left {
    left: 0;
    transform: rotate(180deg);
  }

  &.right {
    right: 0;
  }

  &[data-disabled='true'] {
    filter: grayscale(0.8);
    opacity: 40%;
    pointer-events: none;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    display: none;
  }
}