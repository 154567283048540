@import '../../../styles/settings/colors';

.webdoor-info-list {
  float: left;
  bottom: 45px;
  pointer-events: none;
  width: 100%;
  padding-bottom: 40px;
  z-index: 10;

  &--container {
    width: 100%;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 1024px) {
    max-width: 90%;
  }
}