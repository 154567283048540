@import "../../../styles/settings/colors";
@import "../../../styles/settings/fonts";

@import "../../../styles/tools/mixins";

.news-slider-item {
  width: 100%;
  min-height: 336px;
  max-height: 336px;
  border-radius: 5px;
  display: flex;
  overflow: hidden;

  .link {
    flex: 1;
    text-decoration: none;
  }

  &--info {
    width: 100%;
    height: 100%;
    background-color: $blue;
    padding: 193px 20px 18px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;

    img {
      width: 100%;
      height: 178px;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }

    .date {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      font-family: "Raleway";
      color: $white;
      margin: 0;
    }

    .description {
      p {
        font-size: 18px;
        line-height: 23px;
        font-weight: 400;
        color: $white;
        font-family: "Raleway";
        margin: 0;
        max-height: 70px;
        overflow: hidden;
      }
    }

    span {
      font-size: 14px;
      line-height: 16px;
      font-weight: 700;
      color: $yellow;
      font-family: "Raleway";
      position: absolute;
      left: 20px;
      bottom: 18px;
    }
  }
}
