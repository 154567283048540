@import "../../../../styles/settings/colors";
@import "../../../../styles/settings/fonts";

@import "../../../../styles/tools/mixins";

.input-form {
  position: relative;
  width: 100%;

  .label {
    font-size: 18px;
    line-height: 22px;
    color: #777676;
    font-weight: 400;
    font-family: 'Raleway';
    left: 16px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 2;
    pointer-events: none;
    transition: all ease-in-out 0.4s;

    .required-input {
      color: red;
      font-weight: 700;
      margin-right: 5px;
    }
  }

  .error {
    font-size: 14px;
    color: $dark_blue;
    font-weight: 400;
    margin: 5px 0;
    width: 100%;
  }

  .info {
    @include rem("font-size", 12px);
    color: $black;
    float: left;
    font-weight: 400;
    text-align: right;
    width: 100%;
  }

  input {
    width: 100%;
    height: 55px;
    border: 1px solid #d6d6d6;
    border-radius: 10px;
    outline: none;
    padding: 30px 16px 12px;
    font-size: 18px;
    color: $black;
    font-weight: 400;
    font-family: 'Raleway';

    &:focus {
      & + .label {
        font-size: 12px;
        color: $blue;
        font-weight: 600;
        top: 5px;
        left: 16px;
        transform: translate(0, 0);
      }
    }
  }

  &[data-empty="true"] {
    .label {
      font-size: 12px;
      color: $blue;
      font-weight: 600;
      top: 5px;
      left: 16px;
      transform: translate(0, 0);
    }
  }

  textarea {
    width: 100%;
    height: 296px;
    border: 1px solid #d6d6d6;
    border-radius: 10px;
    padding: 22px 16px 22px 14px;
    resize: vertical;
    outline: none;
    font-size: 18px;
    line-height: 29px;
    color: $black;
    font-weight: 400;
    font-family: 'Raleway';

    & + .info + .label {
      font-size: 18px;
      pointer-events: none;
      top: 29px;
    }
  }
}
