@import "../../../styles/settings/colors";
@import "../../../styles/settings/fonts";

@import "../../../styles/tools/mixins";

.column {
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-rows: repeat(8, auto);
  flex: 1;

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 768px) {
    &:first-child {
      border-radius: 5px 5px 0 0;
    }

    &:last-child {
      border-radius: 0 0 5px 5px;
    }
  }
}