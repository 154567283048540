@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.header-menu-item {
  float: left;
  margin: 0 37px;
  width: auto;
  cursor: pointer;

  &--link {
    padding: 24px 0;
    position: relative;
    float: left;

    .paragraph {
      font-weight: 700;
      color: $white;
      margin: 0;
      text-transform: uppercase;
    }

    & + .header-menu-submenu {
      display: none;
    }

    &::after {
      content: '';
      background-color: transparent;
      width: 20px;
      height: 2px;
      border-radius: 3px;
      position: absolute;
      top: 80%;
      left: 0;
      transition: background-color ease-in 0.2s, top ease-in 0.2s;
    }
  }

  &:before {
    content: '';
    width: 5px;
    height: 5px;
    background-color: $dark_blue;
    border-radius: 100%;
    right: -37px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 20%;
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;

    &:before {
      display: none;
    }

    &>.header-menu-submenu {
      // text-align: end;
      // left: unset;
      right: 0;

      // &::before {
      //   left: 98%;
      //   transform: none;
      // }
    }
  }

  @media (hover: hover) {
    &:hover,
    &[data-active="true"] {
      .header-menu-item {
        @media only screen and (min-width : 1024px) {
          &--link {
            &::after {
              background-color: $light_green;
              top: 65%;
            }
          }
        }
      }
    }

    @media only screen and (min-width : 1024px) {
      &:hover {
        .header-menu-item {
          &--link + div {
            display: inline-flex;
          }
        }
      }
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    padding: 0;
    margin: 0;
    width: 100%;

    .header-menu-item {
      &--link {
        padding: 30px 20px;
        text-align: left;
        width: 100%;
        border-bottom: 1px solid #E4E4E4;

        &[data-active="true"] {
          border-bottom: none;
        }

        .icon {
          @include rem('font-size', 14px);
          color: #0063BE;
          font-weight: 700;
          right: 25px;
          position: absolute;
          top: calc(50% - 1px);
          transform: translate(0, -50%);
        }

        .paragraph {
          @include rem('font-size', 16px);
          color: $dark_blue;
          font-weight: 600;
          transition: all 200ms cubic-bezier(0.250, 0.460, 0.450, 0.940);

          @media only screen and (max-width : 1024px) {
            color: #0063BE !important;
            line-height: 16px;
            font-weight: 700;
          }
        }
      }
    }

    &:before {
      display: none;
    }

    &[data-active-mobile="false"] {
      display: none;

      .header-menu-item {
        &--link {
          .icon {
            right: 25px;
          }

          & + .header-menu-submenu {
            display: none;
          }
        }
      }
    }

    &[data-active-mobile="null"],
    &[data-active-mobile="true"] {
      display: inline-block;
    }

    &[data-active-mobile="true"] {
      .header-menu-item {
        &--link {
          &[data-active="true"] {
            .icon {
              right: calc(100% - 25px);
              transform: translate(0, -50%) rotate(-180deg);
            }

            .paragraph {
              padding-left: 30px;
            }
            
            & + .header-menu-submenu {
              display: flex;
              height: auto;
              flex-direction: column !important;
              position: relative;
              flex-flow: unset;
              height: auto;
              left: 0px;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}