@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.text-banner {
  background-color: $orange;
  float: left;
  margin: 0 auto 40px;
  padding: 120px 0 90px;
  width: 100%;

  &::before {
    content: "";
    width: 200vw;
    height: 100%;
    background-color: $orange;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-50%);
  }

  .container {
    .container-element {
      display: flex;
      flex-flow: column wrap;
      margin: 0 auto;
      justify-content: center;
      gap: 16px;
    }
  }

  .description,
  .main-title {
    color: $white;
    width: 100%;
  }

  .description {
    @include rem('font-size', 17px);
    float: none;
    margin: 0 0 14px;
    line-height: 1.7em;
    max-width: 776px;
    text-align: left;
    width: 100%;
  }

  .link-button {
    background-color: $white;
    border-color: $white;
    color: $orange;
  }

  .icon-large {
    right: 130px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    svg.icon-background {
      rotate: -180deg;
      scale: 2
    }
  }

  .main-title {
    @include rem('font-size', 30px);
    font-weight: 700;
    text-align: start;
    margin: 0;
  }

  .link {
    float: none;
    margin: 30px auto 0 0;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 35px 0 70px;

    .container-element {
      padding-top: 40px;
      text-align: left;
    }

    .main-title {
      margin: 0 0 10px;
      text-align: left;
    }
  }
}