@import '../../styles/settings/colors';

.card {
  height: 395px;
  position: relative;
  width: 100%;

  .bg,
  .link,
  .image,
  .text {
    float: left;
  }

  .bg {
    object-fit: cover;
    width: 100%;
  }

  .image {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .icon {
    background-color: $white;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    left: 0;
    position: absolute;
    top: 0;
    width: 125px;
    
    > div {
      width: 95px;
      
      > img {
        max-height: 40px;
      }
    }

  }
  
  .link {
    background-color: $blue;
    bottom: 30px;
    height: 55px;
    left: 20px;
    position: absolute;
    width: calc(100% - 40px);
  }

  .text {
    align-content: center;
    align-items: center;
    color: $white;
    display: inline-flex;
    justify-content: center;
    flex-flow: row wrap;
    height: 100%;
    float: right;
    padding: 0 25px;
    width: calc(100% - 125px);

    .name {
      font-weight: 700;
      margin: 0;
      line-height: 1em;
      text-transform: uppercase;
    }
  }

  .parallax-outer,
  .parallax-inner {
    height: 100%;
    width: 100%;
  }

  .parallax-outer {
    left: 0;
    position: absolute;
    top: 0;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    width: 340px;
  }
}