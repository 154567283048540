@import "../../../styles/settings/colors";
@import "../../../styles/settings/fonts";

@import "../../../styles/tools/mixins";

.image-text {
  display: flex;
  width: 100%;
  height: 425px;
  background-color: $light_gray;
  border-radius: 5px;
  overflow: hidden;

  &--image {
    width: 796px;
    z-index: 3;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
    }
  }

  &--info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 530px;
    text-align: left;

    span {
      width: fit-content;
      background-color: $green;
      padding: 4px 6px;
      border-radius: 5px;
      margin-bottom: 10px;
      font-family: "Raleway";
      font-size: 12px;
      font-weight: 700;
      color: $dark_blue;
      text-transform: uppercase;
    }

    strong {
      font-family: "Raleway";
      font-size: 36px;
      line-height: 36px;
      font-weight: 300;
      color: $dark_blue;
      margin-bottom: 17px;
    }

    .description {
      font-family: "Raleway";
      font-size: 17px;
      line-height: 25px;
      font-weight: 400;
      color: $dark_blue;
      margin-bottom: 20px;
    }

    .link-button {
      align-self: flex-start;
      background-color: $light_green;
      border: none;
      font-size: 16px;
      line-height: 16px;
      font-weight: 600;
      color: $dark_blue;
      transition: all ease-in-out 0.4s;

      &:hover {
        background-color: $green;
      }
    }
  }

  &[data-type="1"] {
    .image-text {
      &--image {
        order: 2;
      }

      &--info {
        padding: 10px 84px 10px 61px;
      }
    }
  }

  &[data-type="2"] {
    .image-text {
      &--info {
        padding: 10px 61px 10px 84px;
      }
    }
  }

  //Responsivo
  @media only screen and (max-width: 1365px) {
    &--image {
      width: 600px;
    }

    &--info {
      width: 400px;
    }

    &[data-type="1"] {
      .image-text {
        &--info {
          padding: 10px 54px 10px 40px;
        }
      }
    }

    &[data-type="2"] {
      .image-text {
        &--info {
          padding: 10px 40px 10px 54px;
        }
      }
    }
  }

  @media only screen and (max-width: 1023px) {
    flex-direction: column;
    height: auto;

    &--image,
    &--info {
      width: 100%;
    }

    &--image {
      .image {
        height: 310px;
      }
    }

    &[data-type="1"],
    &[data-type="2"] {
      .image-text {
        &--image {
          order: unset;
        }

        &--info {
          padding: 30px 20px;
        }
      }
    }
  }
}
