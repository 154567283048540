@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';


.link-button {
  width: fit-content;
  padding: 11px 32px;
  border: 1px solid $green;
  border-radius: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: $green;
  text-decoration: none;
  letter-spacing: -0.02em;
  float: left;
  pointer-events: auto;
  transition: all ease-in 0.3s;

  &:hover {
    background-color: $green;
    color: $white;
  }

  &[data-align='left'] {
    align-self: flex-start;
  }

  &[data-align='center'] {
    align-self: center;
  }

  &[data-align='right'] {
    align-self: flex-end;
  }
}