@import '../../../../../styles/settings/colors';

.time-line-item-slider-current {
  right: 0;
  position: absolute;
  text-align: right;
  top: 15px;
  width: 90px;

  .current,
  .total {
    font-weight: 400;
    color: $white;
  }

  .current {
    font-weight: 900;
    color: $light_blue;
  }

  &:before {
    background-color: $white;
    bottom: -22px;
    content: '';
    height: 1px;
    right: 0;
    position: absolute;
    width: 65px;
  }

  /* --- QUERIES --- */

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    top: 40px;

    &:before {
      bottom: -26px;
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    top: 30px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    top: 28px;

    &:before {
      display: none;
    }
  }
}