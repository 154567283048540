.card-image-title-text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  max-width: 1124px;
  width: 100%;
  height: auto;
  margin: 30px auto 70px;

  //Responsivo
  @media only screen and (max-width : 1365px) {
    max-width: 736px;
  }

  @media only screen and (max-width : 767px) {
    flex-direction: column;
    max-width: 348px;
  }
}